import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Service } from "../sections";
import { useLang } from "../LangContext";

function Services() {
  const location = useLocation();
  const { data } = useLang();

  useEffect(() => {
    if (location.hash) {
      const elem = document.querySelector(location.hash);
      elem?.scrollIntoView();
    }
  }, [location]);

  return (
    <div>
      {data.services.map((service) => (
        <Service key={service.id} service={service} />
      ))}
    </div>
  );
}

export default Services;
