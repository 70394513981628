import React from "react";
import { NavBar, Footer } from "./components";
import { Outlet } from "react-router-dom";

function Layout() {
  return (
    <div>
      <NavBar />
      <div className="pt-12"></div>
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;
