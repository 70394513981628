import React, { createContext, useContext, useState } from "react";
import { en, fa } from "./locales/index";

const LangContext = createContext();

function LangProvider({ children }) {
  const [lang, setLang] = useState("En");
  const data = lang === "En" ? en : fa;
  const dir = lang === "En" ? "ltr" : "rtl";
  return (
    <LangContext.Provider value={{ lang, setLang, data, dir }}>
      {children}
    </LangContext.Provider>
  );
}

function useLang() {
  return useContext(LangContext);
}

export { LangProvider, useLang };
