import React from "react";
import { useLang } from "../LangContext";

function Service({ service }) {
  const { lang, dir } = useLang();
  return (
    <section className="section group" id={`service-${service.id}`}>
      <div className="container">
        <div className="layout">
          <div className="md:group-odd:col-start-2 md:group-odd:row-start-1">
            <img
              src={service.image}
              alt=""
              className="w-[500px] aspect-video"
            />
          </div>
          <div
            className="ver-4 text-center md:text-start content-center"
            dir={dir}
          >
            <h2 className="header-2">{service.title}</h2>
            <div className="ver-1">
              {service.parags.map((p) => (
                <p key={p} className="base">
                  {p}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="ver-4 pt-4 md:pt-8 text-center">
          <h3 className="header-3">
            {lang === "En"
              ? "Our Services Consists Of The Followings"
              : "خدمات ما شامل موارد زیر میباشد"}
          </h3>
          <div
            className="max-md:grid md:flex flex-wrap md:justify-center gap-4"
            dir={dir}
          >
            {service.items.map((item) => (
              <div
                key={item}
                className="ver-2 content-center justify-center md:bg-neutral-x-100 md:p-4 rounded-lg md:shadow-md"
              >
                <div className="hor-2 items-center">
                  <div className="sub">{item}</div>
                  <div>
                    <svg
                      className="fill-accent-500 w-[18px]"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                    </svg>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Service;
