import React, { useState } from "react";
import LangSwitcher from "./LangSwitcher";
import { NavLink } from "react-router-dom";
import { useLang } from "../LangContext";

function NavBar() {
  const [open, setOpen] = useState(false);
  const { data, dir } = useLang();

  function handleClick(e) {
    setOpen((open) => !open);
  }

  return (
    <div>
      <div
        className={`fixed inset-0 bg-black opacity-70 ${!open && "hidden"}`}
        onClick={handleClick}
      ></div>
      <nav className="py-4 bg-neutral-x-100 shadow-sm fixed top-0 left-0 w-full">
        <div className="container">
          <div className="flex justify-between items-center">
            <div className="font-bold text-lg">
              <NavLink to="/">
                Site<span className="text-accent-500">Wave</span>Pro
              </NavLink>
            </div>
            {/* Desktop Navigation */}
            <ul className="hor-4 max-md:hidden" dir={dir}>
              {data.navLinks.map((navLink) => (
                <li key={navLink.path}>
                  <NavLink
                    to={navLink.path}
                    className={({ isActive }) =>
                      isActive ? "text-accent-500" : ""
                    }
                  >
                    {navLink.title}
                  </NavLink>
                </li>
              ))}
            </ul>
            <LangSwitcher mode={"desktop"} />

            {/* Mobile Navigation */}
            <div className="grid gap-[3px] md:hidden" onClick={handleClick}>
              <div className="w-[18px] h-[3px] bg-black"></div>
              <div className="w-[18px] h-[3px] bg-black"></div>
              <div className="w-[18px] h-[3px] bg-black"></div>
            </div>
            <div
              className={`absolute -bottom-[180px] left-0 w-full bg-neutral-x-100 md:hidden p-4 text-center shadow-xl ${
                !open && "hidden"
              }`}
            >
              <ul>
                {data.navLinks.map((navLink) => (
                  <li className="p-2" key={navLink.path}>
                    <NavLink
                      to={navLink.path}
                      className={({ isActive }) =>
                        isActive ? "text-accent-500 block" : "block"
                      }
                    >
                      {navLink.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
              <div className="p-2">
                <LangSwitcher mode={"mobile"} />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
