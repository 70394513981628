import React from "react";
import { NavLink } from "react-router-dom";
import { useLang } from "../LangContext";

function Hero() {
  const { data, dir } = useLang();
  return (
    <section className="section">
      <div className="container">
        <div className="layout">
          <div className="md:col-start-2 md:row-start-1">
            <img src="/images/hero.jpg" alt="" />
          </div>
          <div
            className="ver-4 text-center md:text-start content-center"
            dir={dir}
          >
            <div className="subheader">{data.hero.subtitle}</div>
            <h1 className="header-1">{data.hero.title}</h1>
            <p className="base">{data.hero.body}</p>
            <NavLink
              to="/contact"
              className="bg-accent-500 hover:bg-accent-600 text-white justify-self-center md:justify-self-start px-4 py-2 rounded-xl shadow-md"
            >
              {data.hero.action}
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
