import React from "react";
import { Hero, Features, About } from "../sections";

function Home() {
  return (
    <div>
      <Hero />
      <Features />
      <About />
    </div>
  );
}

export default Home;
