import React from "react";
import { useLang } from "../LangContext";

function About() {
  const { data, dir } = useLang();
  return (
    <section className="section">
      <div className="container">
        <div className="layout">
          <div>
            <img
              src="/images/about.jpg"
              alt=""
              className="max-md:w-[300px] md:w-[350px]"
            />
          </div>
          <div
            className="ver-4 -mt-8 text-center md:text-start content-center"
            dir={dir}
          >
            <div className="subheader">{data.about.subtitle}</div>
            <h2 className="header-2">{data.about.title}</h2>
            <p className="base">{data.about.body}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
