import React from "react";
import { NavLink } from "react-router-dom";
import { useLang } from "../LangContext";

function Footer() {
  const { data, dir } = useLang();
  return (
    <section className="section footer">
      <div className="container">
        <div
          className="grid grid-cols-1 gap-4 md:grid-cols-[1fr_2fr_1fr] md:justify-between md:justify-items-center items-start"
          dir={dir}
        >
          <div className="ver-2">
            <div className="font-bold text-3xl md:text-4xl">
              Site<span className="text-neutral-x-300">Wave</span>Pro
            </div>
            <p className="text-neutral-x-200">{data.footer.info}</p>
          </div>
          <div className="ver-2">
            <h3 className="header-3">{data.footer.services.title}</h3>
            <div className="grid grid-cols-2 gap-2 justify-items-start">
              {data.footer.services.links.map((link) => (
                <NavLink
                  key={link.title}
                  to={link.path}
                  className="text-neutral-x-200"
                >
                  {link.title}
                </NavLink>
              ))}
            </div>
          </div>
          <div className="ver-2">
            <h3 className="header-3">{data.footer.support.title}</h3>
            <div className="ver-2">
              {data.contact.items.map((item) => (
                <div key={item.key} className="hor-2 text-neutral-x-200">
                  <div>{item.key + ":"}</div>
                  <div>{item.value}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
