import React from "react";
import { useLang } from "../LangContext";

function NotFound() {
  const { data } = useLang();
  return (
    <div className="h-screen grid place-content-center text-center">
      <h1 className="text-6xl font-bold">404</h1>
      <p>{data.notfound}</p>
    </div>
  );
}

export default NotFound;
