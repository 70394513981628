import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LangProvider } from "./LangContext";
import { Home, Services, Contact, NotFound } from "./pages";
import Layout from "./Layout";
import ScrolToTop from "./ScrollToTop";
import "./index.css";

function App() {
  return (
    <LangProvider>
      <BrowserRouter>
        <ScrolToTop>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ScrolToTop>
      </BrowserRouter>
    </LangProvider>
  );
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
